import {useAuth} from "../../lib/auth/providers/AuthProvider";
import {useNavigate} from "react-router-dom";

export function LogoutButton() {
    const auth = useAuth()
    const navigate = useNavigate()

    function _logout(e) {
        e.preventDefault()
        auth.logout().then(() => {navigate('/')})
        return false
    }

    return (
        <button onClick={_logout} className={'underline bg-slate-100 hover:bg-slate-200 p-2'}>Log out</button>
    )
}