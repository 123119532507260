import {supabaseClient} from "../../supabase";

export async function createPerson(name, user_id) {
    const supabase = supabaseClient()

    const { data, error } = await supabase
        .from('people')
        .insert({name, user_id})

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}