import {supabaseClient} from "../../supabase";

export async function login(email, password) {
    const supabase = supabaseClient()

    const { data, error } = await supabase.auth.signInWithPassword({
        email, password
    })

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}