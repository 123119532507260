import {supabaseClient} from "../../supabase";
import {useQuery} from "@tanstack/react-query";

export function useGetTotalWeightDifferenceForPerson(personId) {
    return useQuery({
        queryKey: ['entries', 'total-difference', personId],
        queryFn: () => getTotalWeightDifferenceForPerson(personId)
    })
}

async function getTotalWeightDifferenceForPerson(personId) {
    const supabase = supabaseClient()

    const oldest = await getOldestWeightValue(supabase, personId)
    const newest = await getNewestWeightValue(supabase, personId)

    if (!oldest || !newest) {
        return null
    }

    return parseInt(oldest.value_in_pounds) - parseInt(newest.value_in_pounds)
}

async function getOldestWeightValue(supabase, personId) {
    const { data, error } = await supabase
        .from('weight_entries')
        .select('value_in_pounds')
        .eq('person_id', personId)
        .order('record_date', { ascending: true })
        .limit(1)
        .maybeSingle()

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}

async function getNewestWeightValue(supabase, personId) {
    const { data, error } = await supabase
        .from('weight_entries')
        .select('value_in_pounds')
        .eq('person_id', personId)
        .order('record_date', { ascending: false })
        .limit(1)
        .maybeSingle()

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}
