import {supabaseClient} from "../../supabase";

export async function recordWeighIn(user_id, person_id, value_in_pounds, record_date) {
    const supabase = supabaseClient()

    const { data, error } = await supabase
        .from('weight_entries')
        .insert({user_id, person_id, value_in_pounds, record_date})

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}