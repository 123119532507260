import {useAuth} from "../providers/AuthProvider";

export function LoggedIn({children}) {
    const auth = useAuth()

    if (!auth.user) {
        return null
    }

    return <>{children}</>
}
