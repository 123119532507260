import {useListFoods} from "../../lib/calories/queries/listFoods";

export function FoodList() {
    const { data: foods, isLoading } = useListFoods()

    if (isLoading) {
        return (
            <p>Loading...</p>
        )
    }

    return (
        <table className={'w-full'}>
            <thead>
                <tr>
                    <th className={'text-left'}>Name</th>
                    <th className={'text-right'}>Calories</th>
                </tr>
            </thead>
            <tbody>
                {foods.map(({food_name, calorie_amount}) => (
                    <tr>
                        <td className={'text-left'}>{food_name}</td>
                        <td className={'text-right font-mono'}>{calorie_amount}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
