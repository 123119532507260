import {supabaseClient} from "../../supabase";
import {useQuery} from "@tanstack/react-query";

export function useGetRecentEntries(count) {
    return useQuery({
        queryKey: ['entries', 'recent', count],
        queryFn: () => getRecentEntries(count)
    })
}

async function getRecentEntries(count) {
    const supabase = supabaseClient()

    const { data, error } = await supabase
        .from('weight_entries')
        .select('id, value_in_pounds, record_date, people (name)')
        .order('record_date', { ascending: false })
        .limit(count)

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}
