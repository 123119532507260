import {displayWeightInStone} from "../../../lib/weight/helpers";
import {useGetLowestHighestWeightForPerson} from "../../../lib/weight/queries/getLowestHighestWeightForPerson";

export function LowestHighestWeight({person}) {
    const {data, isLoading} = useGetLowestHighestWeightForPerson(person.id)

    const noDataAvailable = isLoading || data === null || data === undefined

    return noDataAvailable ? (
        <p>No data available</p>
    ) : (
        <>
            <p>Lowest: <code>{displayWeightInStone(data.lowest.value)}</code> ({data.lowest.date})</p>
            <p>Highest: <code>{displayWeightInStone(data.highest.value)}</code> ({data.highest.date})</p>
        </>
    )
}
