import {displayWeightInStone} from "../../../lib/weight/helpers";
import {useGetTotalWeightDifferenceForPerson} from "../../../lib/weight/queries/getTotalWeightDifference";

export function WeightChangeAllTime({person}) {
    const {data: totalDifference, isLoading} = useGetTotalWeightDifferenceForPerson(person.id)

    const noDataAvailable = isLoading || totalDifference === null || totalDifference === undefined

    return noDataAvailable ? (
        <p>No data available</p>
    ) : (
        <p>
            Since records began, you've&nbsp;
            {totalDifference < 0 ? 'gained' : 'lost'}:&nbsp;
            <code>{displayWeightInStone(totalDifference)}</code>
        </p>
    )
}
