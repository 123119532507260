import {Main} from "../components/Main";

export function IndexPage() {
    return (
        <Main>
            <h1 className={'text-4xl font-bold text-slate-900'}>Weight Tracker</h1>
            <p>I'm building this app because I want to track my weight (and eventually other body-related stats)</p>

            <h2 className={'text-2xl font-bold'}>Key features</h2>
            <ul className={'list-disc ml-4'}>
                <li>Record weight for many people (you and a partner, your family, etc)</li>
                <li>See trends over time</li>
            </ul>
        </Main>
    )
}
