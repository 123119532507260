import {useGetPeople} from "../../lib/weight/queries/getPeople";
import {createPerson} from "../../lib/weight/actions/createPerson";
import {useAuth} from "../../lib/auth/providers/AuthProvider";
import {useQueryClient} from "@tanstack/react-query";
import {PageSection} from "../Layout/PageSection";
import {SectionTitle} from "../Layout/SectionTitle";

export function People() {
    const { user } = useAuth()
    const queryClient = useQueryClient()
    const {data, isLoading} = useGetPeople()

    function _createPerson(e) {
        e.preventDefault()

        const form = e.target
        const formData = new FormData(form)

        createPerson(formData.get('person.name'), user.id)
            .then(() => {
                form.reset()
                queryClient.invalidateQueries({ queryKey: ['people'] }).then()
            })
    }

    return (
        <PageSection>
            <SectionTitle text={'People'}/>
            <p>To add a bit of competitive edge, or just to handily track several people all on the same account, you
                can add people here to record weight entries for</p>
            <div>
                {isLoading ? <p>Loading people...</p> : (
                    <ul className={'border p-3 max-w-md'}>
                        {data.map(person => (
                            <li key={person.id}
                                className={'p-1 hover:bg-slate-50 border-b last:border-b-0'}>{person.name}</li>
                        ))}
                    </ul>
                )}
            </div>
            <div>
                <form onSubmit={_createPerson}>
                    <fieldset className={'flex items-center gap-2'}>
                        <label htmlFor={'person.name'}>Name</label>
                        <input type={'text'} name={'person.name'} id={'person.name'}
                               className={'border rounded px-1 py-2'}/>
                        <button type={'submit'} className={'border px-1 py-2 rounded'}>Create person</button>
                    </fieldset>
                </form>
            </div>
        </PageSection>
    )
}