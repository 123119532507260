import {useQuery} from "@tanstack/react-query";
import {supabaseClient} from "../../supabase";

export function useListFoods() {
    return useQuery({
        queryKey: ['foods'],
        queryFn: getListFoods
    })
}

async function getListFoods() {
    const supabase = supabaseClient()

    const { data, error } = await supabase
        .from('food')
        .select()
        .order('food_name')

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}
