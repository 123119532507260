import {Main} from "../components/Main";
import {useForm} from "react-hook-form";
import {Link} from "../components/Link";
import {useAuth} from "../lib/auth/providers/AuthProvider";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export function LoginPage() {
    const auth = useAuth()
    const navigate = useNavigate()
    const form = useForm()
    const [error, setError] = useState('')

    if (auth.isLoggedIn) {
        navigate('/dashboard')
        return null
    }

    function _login(data) {
        setError('')
        auth.login(data.email, data.password)
            .then(() => {
                navigate('/dashboard')
            })
            .catch(error => {
                setError(error.message)
            })
    }

    return (
        <Main>
            <h1 className={'text-4xl font-bold text-slate-900'}>Login</h1>
            <p>Welcome back! Please login to your account. If you don't have an account, <Link href={'/register'}>Sign
                up here</Link></p>

            <form onSubmit={form.handleSubmit(_login)} className={'border shadow rounded p-4 space-y-4'}>

                <fieldset className={'flex flex-col gap-2'}>
                    <label htmlFor={'email'}>Email</label>
                    <input type={'email'} {...form.register('email', {required: true})}
                           className={'rounded bg-slate-100 border border-slate-300 px-2 py-1'}/>
                </fieldset>

                <fieldset className={'flex flex-col gap-2'}>
                    <label htmlFor={'password'}>Password</label>
                    <input type={'password'} {...form.register('password', {required: true})}
                           className={'rounded bg-slate-100 border border-slate-300 px-2 py-1'}/>
                </fieldset>

                <p className={'text-red-500 font-bold'}>{error && error}</p>

                <p>
                    <button
                        type={'submit'}
                        className={'hover:bg-blue-400 bg-blue-500 transition-colors text-white font-bold px-4 py-2 shadow focus:shadow-none rounded'}
                    >Log in
                    </button>
                </p>

            </form>
        </Main>
    )
}