import {PageSection} from "../Layout/PageSection";
import {SectionTitle} from "../Layout/SectionTitle";
import {useGetPeople} from "../../lib/weight/queries/getPeople";
import {WeightChangeAllTime} from "./Trends/WeightChangeAllTime";
import {LowestHighestWeight} from "./Trends/LowestHighestWeight";

export function Trends() {
    const { data: people } = useGetPeople()

    return (
        <PageSection>
            <SectionTitle text={'Trends'}/>
            { people?.map(person => (
                <div className={'space-y-2'} key={person.id}>
                    <p className={'font-semibold'}>{person.name}</p>
                    <WeightChangeAllTime person={person}/>
                    <LowestHighestWeight person={person}/>
                </div>
            ))}
        </PageSection>
    )
}