import {useAuth} from "../providers/AuthProvider";

export function LoggedOut({children}) {
    const auth = useAuth()

    if (auth.user) {
        return null
    }

    return <>{children}</>
}
