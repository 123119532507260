import {useAuth} from "../../lib/auth/providers/AuthProvider";
import {useQueryClient} from "@tanstack/react-query";
import {createFood} from "../../lib/calories/actions/createFood";

export function AddFoodForm() {
    const {user} = useAuth()
    const queryClient = useQueryClient()

    function _createFood(e) {
        e.preventDefault()

        const form = e.target
        const formData = new FormData(form)

        const foodName = formData.get('food_name')
        const calorieAmount = formData.get('calorie_amount')

        createFood(foodName, calorieAmount, user.id).then(() => {
            form.reset()
            queryClient.invalidateQueries({queryKey: ['foods']}).then()
        })
    }

    return (
        <form onSubmit={_createFood}>
            <fieldset className={'flex items-center gap-2'}>
                <p>
                <label htmlFor={'food_name'}>Food</label>
                <input type={'text'} name={'food_name'} id={'food_name'} className={'border rounded px-1 py-2'}/>
                </p>

                <p>
                <label htmlFor={'calorie_amount'}>Calories</label>
                <input type={'number'} name={'calorie_amount'} id={'calorie_amount'} className={'border rounded px-1 py-2'}/>
                </p>

                <p>
                <button type={'submit'} className={'border px-1 py-2 rounded'}>Add food</button>
                </p>
            </fieldset>
        </form>
    )
}