import {supabaseClient} from "../../supabase";

export async function getAuthSession() {
    const supabase = supabaseClient()

    const { data, error } = supabase.auth.getSession()

    if (error) {
        console.error(error)
        throw new Error(error)
    }

    return data
}
