import {useAuth} from "../../lib/auth/providers/AuthProvider";
import {useQueryClient} from "@tanstack/react-query";
import {recordWeighIn} from "../../lib/weight/actions/recordWeighIn";
import {useGetPeople} from "../../lib/weight/queries/getPeople";
import {PageSection} from "../Layout/PageSection";
import {SectionTitle} from "../Layout/SectionTitle";

export function RecordWeight() {
    const {user} = useAuth()
    const queryClient = useQueryClient()
    const {data: people, isLoading} = useGetPeople()

    function _recordWeight(e) {
        e.preventDefault()

        const form = e.target
        const formData = new FormData(form)

        const personId = formData.get('person.id')
        const recordDate = formData.get('record_date')

        const stones = parseInt(formData.get('stones'))
        const pounds = parseInt(formData.get('pounds'))
        const totalPounds = stones * 14 + pounds

        recordWeighIn(user.id, personId, totalPounds, recordDate).then(() => {
            form.reset()
            queryClient.invalidateQueries({queryKey: ['entries']}).then()
        })
    }

    return (
        <PageSection>
            <SectionTitle text={'Record a weigh-in'}/>
            <p>Add entries here to track your weight</p>

            <form onSubmit={_recordWeight}>
                <fieldset className={'flex items-center gap-2'}>
                    <label htmlFor={'person.id'}>Person</label>
                    {isLoading ? <p>Loading people...</p> : (
                        <select name={'person.id'} id={'person.id'} className={'border rounded px-1 py-2'}>
                            {people?.map(person => (
                                <option key={person.id} value={person.id}>{person.name}</option>
                            ))}
                        </select>
                    )}
                    <input type={'date'} name={'record_date'} id={'record_date'}
                           className={'border rounded px-1 py-2'}/>
                    <input type={'number'} name={'stones'} id={'stones'} placeholder={'Stone'}
                           className={'border rounded px-1 py-2'}/>
                    <input type={'number'} name={'pounds'} id={'pounds'} placeholder={'Pounds'}
                           className={'border rounded px-1 py-2'}/>
                    <button type={'submit'} className={'border px-1 py-2 rounded'}>Record weigh-in</button>
                </fieldset>
            </form>
        </PageSection>
    )
}