import {displayWeightInStone} from "../../lib/weight/helpers";
import {useGetRecentEntries} from "../../lib/weight/queries/getRecentEntries";
import {PageSection} from "../Layout/PageSection";
import {SectionTitle} from "../Layout/SectionTitle";

export function RecentWeighIns() {
    const {data, isLoading} = useGetRecentEntries(5)

    return (
        <PageSection>
            <SectionTitle text={'Recent weigh-ins (your last 5 entries)'}/>
            {isLoading ? <p>Loading entries...</p> : (
                <table className={'w-full'}>
                    <thead>
                    <tr className={'border-b'}>
                        <th className={'text-left'}>Person</th>
                        <th className={'text-left'}>Recorded weight</th>
                        <th className={'text-right'}>Date taken</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(entry => (
                        <tr key={entry.id}>
                            <td className={'p-2 text-left'}>{entry.people.name}</td>
                            <td className={'p-2 text-left'}><code>{displayWeightInStone(entry.value_in_pounds)}</code>
                            </td>
                            <td className={'p-2 text-right'}>{new Date(entry.record_date).toLocaleDateString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </PageSection>
    )
}
