import {supabaseClient} from "../../supabase";

export async function createFood(food_name, calorie_amount, user_id) {
    const supabase = supabaseClient()

    const { data, error } = await supabase
        .from('food')
        .insert({food_name, calorie_amount, user_id})

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}
