import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../providers/AuthProvider";

export function useRedirectIfNotLoggedIn(to) {
    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!auth.isLoggedIn) {
            navigate('/login')
        }
        // eslint-disable-next-line
    }, [])
}