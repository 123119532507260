
export function convertPoundsToStone(pounds) {
    return pounds / 14
}

/**
 * Always returns a positive number.
 * It's up to the calling function to decide what to do with negatives.
 */
export function displayWeightInStone(pounds) {
    const stone = Math.abs(convertPoundsToStone(pounds))
    const remainingPounds = Math.abs(pounds % 14)

    return `${Math.floor(stone)} st, ${remainingPounds} lbs`
}
