import {supabaseClient} from "../../supabase";
import {useQuery} from "@tanstack/react-query";

export function useGetLowestHighestWeightForPerson(personId) {
    return useQuery({
        queryKey: ['entries', 'lowest-highest', personId],
        queryFn: () => getLowestHighestWeightForPerson(personId)
    })
}

async function getLowestHighestWeightForPerson(personId) {
    const supabase = supabaseClient()

    const lowest = await getLowestWeightValue(supabase, personId)
    const highest = await getHighestWeightValue(supabase, personId)

    if (!lowest || !highest) {
        return null
    }

    return {
        lowest: {
            value: parseInt(lowest.value_in_pounds),
            date: lowest.record_date,
        },
        highest: {
            value: parseInt(highest.value_in_pounds),
            date: highest.record_date,
        },
    }
}

async function getLowestWeightValue(supabase, personId) {
    const { data, error } = await supabase
        .from('weight_entries')
        .select('value_in_pounds, record_date')
        .eq('person_id', personId)
        .order('value_in_pounds', { ascending: true })
        .order('record_date', { ascending: false })
        .limit(1)
        .maybeSingle()

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}

async function getHighestWeightValue(supabase, personId) {
    const { data, error } = await supabase
        .from('weight_entries')
        .select('value_in_pounds, record_date')
        .eq('person_id', personId)
        .order('value_in_pounds', { ascending: false })
        .order('record_date', { ascending: false })
        .limit(1)
        .maybeSingle()

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}
