import {createBrowserRouter} from "react-router-dom";
import {LoginPage} from "../pages/LoginPage";
import React from "react";
import {IndexPage} from "../pages/IndexPage";
import {DashboardPage} from "../pages/DashboardPage";
import {Root} from "../pages/Root";
import {CaloriesPage} from "../pages/CaloriesPage";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        children: [
            {
                path: '/',
                element: <IndexPage/>,
            },
            {
                path: '/login',
                element: <LoginPage/>,
            },
            {
                path: '/dashboard',
                element: <DashboardPage/>,
            },
            {
                path: '/calories',
                element: <CaloriesPage />,
            }
        ]
    },
])
