import {Main} from "../components/Main";
import {useRedirectIfNotLoggedIn} from "../lib/auth/hooks/useRedirectIfNotLoggedIn";
import {LoggedIn} from "../lib/auth/components/LoggedIn";
import {FoodList} from "../components/Calories/FoodList";
import {AddFoodForm} from "../components/Calories/AddFoodForm";

export function CaloriesPage() {
    useRedirectIfNotLoggedIn('/login')

    return (
        <LoggedIn>
            <Main>
                <p>BETA: Track calorie intake</p>
                <FoodList />
                <AddFoodForm />
            </Main>
        </LoggedIn>
    )
}
