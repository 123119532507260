import {Main} from "../components/Main";
import {useRedirectIfNotLoggedIn} from "../lib/auth/hooks/useRedirectIfNotLoggedIn";
import {LoggedIn} from "../lib/auth/components/LoggedIn";
import {RecentWeighIns} from "../components/Dashboard/RecentWeighIns";
import {People} from "../components/Dashboard/People";
import {RecordWeight} from "../components/Dashboard/RecordWeight";
import {Trends} from "../components/Dashboard/Trends";

export function DashboardPage() {
    useRedirectIfNotLoggedIn('/login')

    return (
        <LoggedIn>
            <Main>
                <p>Welcome, good citizen</p>
                <RecordWeight/>
                <Trends/>
                <RecentWeighIns/>
                <People/>
            </Main>
        </LoggedIn>
    )
}
