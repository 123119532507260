import {createClient} from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY

const client = createClient(
    supabaseUrl,
    supabaseKey
)

export function supabaseClient() {
    return client
}
