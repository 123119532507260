import {Main} from "../components/Main";
import {Outlet} from "react-router-dom";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";

export function Root() {
    return (
        <div className={'min-h-dvh flex flex-col justify-between gap-4 p-4'}>
            <Header/>
            <Main>
                <Outlet/>
            </Main>
            <Footer/>
        </div>
    )
}
