import {supabaseClient} from "../../supabase";
import {useQuery} from "@tanstack/react-query";

export function useGetPeople() {
    return useQuery({
        queryKey: ['people'],
        queryFn: getPeople
    })
}

async function getPeople() {
    const supabase = supabaseClient()

    const { data, error } = await supabase
        .from('people')
        .select()
        .order('name')

    if (error) {
        console.error(error)
        throw new Error(error.message)
    }

    return data
}
