import {createContext, useContext, useEffect, useState} from "react";
import {getAuthSession} from "../queries/getAuthSession";
import {login} from "../actions/login";
import {logout} from "../actions/logout";
import {supabaseClient} from "../../supabase";

const AuthContext = createContext(undefined);

export function AuthProvider({children}) {
    const [session, setSession] = useState({})

    useEffect(() => {
        getAuthSession().then(session => {
            setSession(session)
        })

        const {data: { subscription } } = supabaseClient().auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
        return () => subscription.unsubscribe()
    }, [])

    const value = {
        user: session?.user,
        isLoggedIn: !!session,
        login: async (email, password) => {
            await login(email, password).then(data => setSession(data.session))
        },
        logout: async() => {
            await logout()
            setSession(undefined)
        }
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext)

    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }

    return context
}
